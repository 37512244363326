import gql from 'graphql-tag';

export default gql(`
query {
  rdsGetCurrentAuthenticatedUser {
    id
    forwoodUuid
    username
    preferredUsername
    email
    firstName
    middleName
    lastName
    preferredName
    cognitoUuid
    roles
    teams
    dac
    dacCreatedAt
    dacTarget
    status
    phoneNumber
    companyStructure
    contractorCompany
    contractorCompanyUuid
    physicalLocation
    locale
    accessExpiryDate
    timezone
    ssoId
    personnelNumber
    jobRoleCategory
    employmentStatus
    inCrmLeadershipRole
    inOperationalRole
    federatedIdentity
    federatedIdentityDetails
    lastLogin
    createdAt
    updatedAt
    hmac
  }
}`);
